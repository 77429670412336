<template>
    <form-students
      :service="model.service"
      :company="formCompany.model"
      :plan="model.plan"
    ></form-students>
</template>
<script>
import { meanBy, sum, sumBy } from 'lodash'

/* End of vendors
-------------------------------------------------- */
/* app
-------------------------------------------------- */
import { mapFilters } from '@/filters/map-filters'
import { buildQueryString } from '@/helpers'
import form from '@/views/common/form'
import { addOnTotalize } from '@/views/company/calculate-total'
import modelCompany from '@/views/company/model'
import model from './model'
import rules from './rules'

/* End of app
-------------------------------------------------- */

export default {
  name: 'RegisterStudents',
  components: {
    FormStudents: () => import('@/views/signup/form-students')
  },
  props: {
    detailType: {
      type: String,
      required: false,
      default: ''
    },
    frequencyBilling: {
      type: String,
      required: false,
      default: ''
    },
    plan: {
      type: Object,
      required: false,
      default() {
        return {
          features: [],
          id: ''
        }
      }
    }
  },
  data() {
    return {
      form: form({ model, rules }),
      formCompany: form({ model: modelCompany }),
      loading: {
        addOn: false,
        plan: false
      },
      list: {
        addOns: []
      },
      modal: {
        register: false
      },
      model: {
        service: {},
        plan: {}
      }
    }
  },
  computed: {
    hidePersonalizePlan: _this => ['site', 'crm', 'package'].includes(_this.$route.query.source),
    sourceIsCrm: _this => _this.$route.query.source === 'crm',
    isIsvService: _this => _this.service === 'isv',
    crmParams: _this => {
      if (_this.$route.query.product) {
        return _this.$route.query.product.split('-')
      }

      return []
    },
    product: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    service: _this => {
      if (_this.$route.query.product && _this.$route.query.plan) {
        return _this.$route.query.product.toLowerCase()
      }
      if (_this.crmParams.length > 0) {
        return _this.crmParams[0].trim().toLowerCase()
      }

      return ''
    },
    planName: _this => {
      if (_this.$route.query.plan) {
        return _this.$route.query.plan
      }
      if (_this.crmParams.length > 1) {
        return _this.crmParams[1].trim()
      }

      return ''
    },
    periodicidad: _this => {
      if (_this.$route.query.periodicidad) {
        return _this.$route.query.periodicidad
      }
      if (_this.crmParams.length > 2) {
        return _this.crmParams[2].trim()
      }

      return ''
    },
    price: _this => {
      if (_this.$route.query.price) {
        return _this.$route.query.price
      }
      if (_this.crmParams.length > 3) {
        return _this.crmParams[3].trim()
      }

      return ''
    },
    modeBill: _this => {
      if (_this.serviceId === 7 || _this.serviceId === 11) {
        const mode = _this.planName.replace(/\s/g, '')
        if (
          mode.toLowerCase() === 'demanda' ||
          mode.toLowerCase() === 'paqueteindividual'
        ) {
          return mode
        }
        if (
          _this.form.model &&
          _this.form.model.planMode.toLowerCase() === 'paquete'
        ) {
          return 'paqueteGlobal'
        }

        return ''
      }

      return ''
    },
    serviceId: _this => {
      if (_this.service === 'fe') {
        return 1
      }
      if (_this.service === 'aliaddo') {
        return 2
      }
      if (_this.service === 'isv') {
        return 7
      }
      if (_this.service === 'aliaddopos') {
        return 8
      }
      if (_this.service === 'aliaddonominafull') {
        return 10
      }
      if (_this.service === 'aliaddonominalite') {
        return 11
      }
      if (_this.service === 'aliaddonominaisv') {
        return 12
      }

      return -1
    },
    companyServiceFrequencyBilling: _this => {
      if (_this.frequencyBilling) {
        return _this.frequencyBilling
      }
      let frecuency = ''
      switch (_this.periodicidad) {
        case 'Anual':
          frecuency = 'Yearly'
          break
        case 'Mensual':
          frecuency = 'Monthly'
          break
        case 'Trimestral':
          frecuency = 'Quarterly'
          break
        case 'Semestral':
          frecuency = 'Semiannual'
          break
        default:
          frecuency = ''
      }

      return frecuency
    },
    companyServiceFrequencyBillingQuantity: _this => {
      let frecuencyQuantity = 0
      switch (_this.companyServiceFrequencyBilling) {
        case 'Yearly':
          frecuencyQuantity = 12
          break
        case 'Monthly':
          frecuencyQuantity = 1
          break
        case 'Quarterly':
          frecuencyQuantity = 3
          break
        case 'Semiannual':
          frecuencyQuantity = 6
          break
        default:
          frecuencyQuantity = 0
      }

      return frecuencyQuantity
    },
    companyServicePrice: _this => {
      let price = ''
      switch (_this.companyServiceFrequencyBilling) {
        case 'Yearly':
          price =
            _this.price === 'new'
              ? _this.form.model.planPriceYearlyNew
              : _this.form.model.planPriceYearly
          break
        case 'Monthly':
          price =
            _this.price === 'new'
              ? _this.form.model.planPriceMonthlyNew
              : _this.form.model.planPriceMonthly
          break
        case 'Quarterly':
          price =
            _this.price === 'new'
              ? _this.form.model.planPriceQuarterlyNew
              : _this.form.model.planPriceQuarterly
          break
        case 'Semiannual':
          price =
            _this.price === 'new'
              ? _this.form.model.planPriceSemiannualNew
              : _this.form.model.planPriceSemiannual
          break
        default:
          price = 0
      }

      return price || 0
    },
    companyServicePlanTaxBase: _this => _this.companyServicePrice -
      (
        _this.companyServicePrice *
        ((_this.form.model.planTaxPercentIncluded || 0) / 100)
      ).toFixed(2),
    companyServicePlanIvaBase: _this => _this.companyServicePrice -
      (
        _this.companyServicePrice *
        ((_this.form.model.planTaxPercentIncluded || 0) / 100)
      ).toFixed(2),
    companyServicePlanIvaPercent: _this => _this.form.model.planTaxPercentIncrease ||
      _this.form.model.planTaxPercentIncluded ||
      0,
    companyServicePlanIvaValue: _this => +(
      _this.companyServicePrice *
        ((_this.form.model.planTaxPercentIncrease ||
          _this.form.model.planTaxPercentIncluded ||
          0) /
          100)
    ).toFixed(2),
    companyPlanFeaturePrice: _this => sum(
      _this.form.model.features
        .filter(item => item.featureAddonQuantity)
        .map(
          item => +(
            item.featureUnitPriceCustom *
                +item.featureAddonQuantity *
                +_this.companyServiceFrequencyBillingQuantity
          ).toFixed(2)
        )
    ),
    companyPlanFeatureTaxBase: _this => _this.companyPlanFeaturePrice -
      (
        _this.companyPlanFeaturePrice *
        ((_this.form.model.planTaxPercentIncluded || 0) / 100)
      ).toFixed(2),
    companyPlanFeatureIvaBase: _this => _this.companyPlanFeaturePrice -
      (
        _this.companyPlanFeaturePrice *
        ((_this.form.model.planTaxPercentIncluded || 0) / 100)
      ).toFixed(2),
    companyPlanFeatureIvaPercent: _this => _this.form.model.planTaxPercentIncrease ||
      _this.form.model.planTaxPercentIncluded ||
      0,
    companyPlanFeatureIvaValue: _this => +(
      _this.companyPlanFeaturePrice *
        ((_this.form.model.planTaxPercentIncrease ||
          _this.form.model.planTaxPercentIncluded ||
          0) /
          100)
    ).toFixed(2),
    companyPlanFeatureAmount: _this => +(
      _this.companyPlanFeaturePrice *
        ((_this.form.model.planTaxPercentIncrease ||
          _this.form.model.planTaxPercentIncluded ||
          0) /
          100)
    ).toFixed(2),
    companyServiceAddOnPrice: _this => sumBy(_this.formCompany.model.addOns, 'addOnPrice'),
    companyServiceAddOnTaxBase: _this => sumBy(_this.formCompany.model.addOns, 'addOnTaxBase'),
    companyServiceAddOnIvaBase: _this => sumBy(_this.formCompany.model.addOns, 'addOnTaxBase'),
    // eslint-disable-next-line vue/max-len
    companyServiceAddOnIvaPercent: _this => meanBy(_this.formCompany.model.addOns, item => item.addOnIvaPercent),
    companyServiceAddOnIvaValue: _this => sumBy(_this.formCompany.model.addOns, 'addOnIvaValue'),
    companyServiceAmount: _this => {
      if (_this.modeBill && _this.modeBill.toLowerCase() === 'demanda') {
        return _this.companyServiceAddOnPrice + _this.companyPlanFeaturePrice
      }

      return (
        _this.companyServicePrice +
        _this.companyServiceAddOnPrice +
        _this.companyPlanFeaturePrice
      )
    },
    companyServiceTaxBase: _this => _this.companyServicePlanTaxBase +
      _this.companyPlanFeatureTaxBase +
      _this.companyServiceAddOnTaxBase,
    companyServiceIvaBase: _this => {
      let ivaBase = 0
      if (_this.companyServicePlanIvaPercent > 0) {
        ivaBase += _this.companyServicePlanIvaPercent
      }

      if (_this.companyServicePlanIvaPercent > 0) {
        ivaBase += _this.companyServicePlanIvaPercent
      }

      if (_this.companyServiceAddOnIvaPercent > 0) {
        ivaBase += _this.companyServiceAddOnIvaPercent
      }

      return ivaBase
    },
    companyServiceIvaPercent: _this => {
      if (
        _this.companyServicePlanIvaPercent > 0 &&
        _this.companyServiceAddOnIvaPercent > 0
      ) {
        return +(
          (_this.companyServicePlanIvaPercent +
            _this.companyServiceAddOnIvaPercent) /
          2
        ).toFixed(2)
      }

      return (
        (_this.companyServicePlanIvaPercent || 0) +
        (_this.companyServiceAddOnIvaPercent || 0)
      )
    },
    companyServiceIvaValue: _this => +(_this.companyServicePlanIvaValue || 0) +
      (_this.companyServiceAddOnIvaValue || 0),
    companyServiceAmountTotal: _this => {
      let amountTotal = _this.companyServiceAmount

      if (_this.form.model.planTaxPercentIncrease > 0) {
        amountTotal += _this.companyServicePlanIvaValue
      }

      amountTotal +=
        sum(
          // eslint-disable-next-line vue/max-len
          _this.formCompany.model.addOns.map(item => (item.addOnTaxPercentIncrease ? item.addOnIvaValue : 0)
          )
        ) || 0

      return amountTotal
    }
  },
  mounted() {
    this.getPlan()
    this.getService()
  },
  methods: {
    ...mapFilters(['toCamelCase']),
    ...{
      addOnTotalize
    },
    onSelectAddOn(value) {
      const _this = this
      if (value.length > 0) {
        _this.formCompany.model.addOns = value.map(item => addOnTotalize(item))

        return
      }
      _this.formCompany.model.addOns = value
    },
    onNext() {
      const _this = this
      _this.formCompany.model = {
        planId: _this.form.model.id,
        serviceId: _this.serviceId,
        features: _this.form.model.features,
        addOns: _this.formCompany.model.addOns,
        companyServicePrice: _this.companyServicePrice,
        companyServiceSource: _this.$route.query.source,
        companyServiceModeBill: _this.modeBill,
        companyServiceFrequencyBilling: _this.companyServiceFrequencyBilling
      }

      _this.$emit('update:company', _this.formCompany.model)

      if (['crm', 'site'].includes(_this.$route.query.source)) {
        _this.modal.register = true
      } else {
        _this.$emit('next', {
          company: _this.formCompany.model,
          voucher: {
            voucherRecurrence: _this.companyServiceFrequencyBilling,
            voucherAmount: _this.companyServiceAmount,
            voucherTaxBase: _this.companyServiceTaxBase,
            voucherIvaBase: _this.companyServiceIvaBase,
            voucherIvaPercent: _this.companyServiceIvaPercent,
            voucherIvaValue: _this.companyServiceIvaValue,
            voucherAmountTotal: _this.companyServiceAmountTotal
          }
        })
      }
    },
    getFeatures() {
      return this.form.model.features
        .filter(
          item => item.featureType !== 'access' &&
            +item.featureUnitPriceCustom > 0 &&
            item.featureIsVisible === 'true'
        )
        .sort((a, b) => a.featureValue - b.featureValue)
    },
    getAddOns() {
      const _this = this

      _this.formCompany.model.addOns = []

      _this.loading.addOn = true

      return _this.$http
        .get(
          `add-ons/${_this.serviceId}/${_this.form.model.id}/findByServiceByPlan?onlyEnabled=true`
        )
        .then(result => {
          _this.list.addOns = result.body
          if (_this.list.addOns.length > 0 && this.detailType === 'Apertura') {
            _this.onSelectAddOn(
              _this.list.addOns.filter(item => item.addOnIsMandatory)
            )
          }

          return result.body
        })
        .finally(() => (_this.loading.addOn = false))
    },
    getPlan() {
      const _this = this
      if (_this.plan && _this.plan.id > 0) {
        _this.form.model = _this.plan

        return _this.getAddOns().then(() => {
          if (_this.hidePersonalizePlan) {
            _this.onNext()
          }
        })
      }
      _this.loading.plan = true
      const queryString = buildQueryString({
        serviceId: _this.serviceId,
        planName: _this.planName,
        planEnabled: true
      })

      _this.loading.plan = true

      return _this.$http
        .get(`plans/findByName?${queryString}`)
        .then(response => {
          if (!response.data || response.data.id < 1) {
            this.$message({
              showClose: true,
              message: _this.$tc('messageNoFoundPlan'),
              type: 'error'
            })
          } else if (response.data.id !== 383) {
            this.$message({
              showClose: true,
              message: _this.$tc('messageNoFoundPlan'),
              type: 'error'
            })
          } else {
            _this.form.model = response.data
            _this.model.plan = response.data
          }

          /* ----------  Validar si la periodicidad esta mal y mostrar alerta  ---------- */
          if (
            !_this.form.model.planFree &&
            _this.companyServiceAmountTotal <= 0 &&
            !_this.isIsvService
          ) {
            this.$message({
              showClose: true,
              message: _this.$tc('messagePlanPriceError'),
              type: 'error'
            })

            return
          }

          _this.getAddOns().then(() => {
            if (_this.hidePersonalizePlan) {
              _this.onNext()
            }
          })
        })
        .finally(() => (_this.loading.plan = false))
    },
    getService() {
      const _this = this

      _this.loading.service = true

      _this.$http
        .get(`services/${_this.serviceId}`)
        .then(response => (_this.model.service = response.data))
        .finally(() => (_this.loading.service = false))
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/transactions/personalize.scss';
@import '~element-ui/lib/theme-chalk/display.css';

.el-alert.el-alert--success {
  background-color: var(--classyPalette4);
  border-color: var(--classyPalette4);
  color: var(--spotPalette4);
}

.ellipse {
  height: 45px;
  line-height: 1.4em;
  display: flex;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.image-bg {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-image: url('../../assets/img/svg/login-erp.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
}
</style>
